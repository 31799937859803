import React from 'react';
import EducationItemEntry from './educationItemEntry'

const EducationListing = (props) => (
  <div className="education-listing-wrapper">
    {props.items.map((item, index) => (
      <EducationItemEntry content={item} key={index} />
    ))}
  </div>
)

export default EducationListing