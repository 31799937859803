import React from'react';

const EducationItemEntry = (props) => (
  // <div className="item-entry-wrapper">
  //   {props.content}
  // </div>
  <div className="education-list-wrapper">
    <p className="education-year">{props.content.year ? props.content.year : ''} </p>
    <p className="education">{props.content.achievement ? props.content.achievement : ''}</p>
  </div>
)

export default EducationItemEntry;