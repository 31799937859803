import React from 'react'

const ExperienceListing = (props) => (
  <div className="experience-wrapper">
    {props.experience.map((item, index) => (
      <li className="experience-list" key={index}>{item.position}</li>
    ))}
  </div>
)

export default ExperienceListing;