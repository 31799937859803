import React from "react";
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import EducationListing from '../components/educationListing';
import ExperienceListing from '../components/experienceListing';
// import data.markdownRemark.frontmatter from "../../site/editor/about.json"

const AboutPage = ({ data }) => {
  const DrKooImage = getImage(data.markdownRemark.frontmatter.drKooImage);
  const AboutHeroImage = data.markdownRemark.frontmatter.heroImage.childImageSharp.original.src

  return (
    <Layout>
      <div className="about-hero-wrapper">
        <div className="about-hero" style={{ "backgroundImage": `url(${AboutHeroImage})` }}>
        </div>
      </div>
      <div className="about-body-wrapper">
        <div className="about-body-top-wrapper">
          <div className="about-image-background">
            <div className="about-image-wrapper">
              <GatsbyImage image={DrKooImage} alt="구본찬 원장님" />
            </div>
          </div>
          <div className="about-introduction-wrapper">
            <div className="about-introduction" 
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}>
            </div>
          </div>
        </div>
        <div className="about-body-career-wrapper">
          <h2>원장 이력 및 약력 소개</h2>
          <div className="career-wrapper">
            <div className="education-inner-wrapper">
              <EducationListing items={data.markdownRemark.frontmatter.education} />
            </div>
            <div className="career-inner-wrapper">
              <ExperienceListing experience={data.markdownRemark.frontmatter.experience} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const intro = graphql`
query MyQuery {
  markdownRemark(frontmatter: {slug: {eq: "/about"}}) {
    id
    html
    frontmatter {
      education {
        year
        achievement
      }
      experience {
        position
      }
      heroImage {
        childImageSharp {
          original {
            src
          }
        }
      }
      drKooImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}`